import { useContext } from "react";
import styles from "./style.module.css";
import ToggleMenuContext from "../../store/toggleMenuContext";

const Hamburger = () => {
  const { setShowMenu } = useContext(ToggleMenuContext);
  return (
    <div
      className={styles.toggleButton}
      onClick={() => {
        setShowMenu((prev) => !prev);
      }}
    >
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
    </div>
  );
};

export default Hamburger;
