import { useContext } from "react";
import { NavLink } from "react-router-dom";
import ToggleMenuContext from "../../store/toggleMenuContext";
import styles from "./style.module.css";

const NavLinks = () => {
  const { showMenu, setShowMenu } = useContext(ToggleMenuContext);
  const activeLinkHandler = ({ isActive }) =>
    isActive ? styles.activeRoute : undefined;
  return (
    <ul
      className={`${styles.navlinks} ${showMenu && styles.active}`}
      onClick={() => setShowMenu(false)}
    >
      <li>
        <NavLink to="/" end className={activeLinkHandler}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" className={activeLinkHandler}>
          About
        </NavLink>
      </li>
      <li>
        <NavLink to="/gallery" className={activeLinkHandler}>
          Gallery
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact" className={activeLinkHandler}>
          Contact
        </NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;
