import { createContext, useState } from "react";

const ToggleMenuContext = createContext({
  showMenu: false,
  setShowMenu: () => {},
});

export const ToggleMenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const context = {
    showMenu,
    setShowMenu,
  };
  return (
    <ToggleMenuContext.Provider value={context}>
      {children}
    </ToggleMenuContext.Provider>
  );
};

export default ToggleMenuContext;
