import BrandLogo from "./BrandLogo";
import Hamburger from "./Hamburger";
import NavLinks from "./NavLinks";
import styles from "./style.module.css";

const NavBar = () => {
  return (
    <nav className={styles.navbar}>
      <BrandLogo />
      <Hamburger />
      <NavLinks />
    </nav>
  );
};

export default NavBar;
